import Home from "./Home";
import Conference from "./Conference";

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: "/",
    element: <Home />,
  },
  { path: "/conference/:id", element: <Conference /> },
];
