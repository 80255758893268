import {
  Button,
  Flex,
  HStack,
  Image,
  PinInput,
  PinInputField,
  Text,
} from "@chakra-ui/react";
import QuincyLogo from "../../assets/quincy.png";
import { useCallback } from "react";
import { useWebRTC } from "../../context/WebRTSProvider";
import { css, Global } from "@emotion/react";

const HomeScreen = () => {
  const { call, sessionId, callState } = useWebRTC();

  const setCode = useCallback(
    (value: string) => {
      sessionId.current = value;
    },
    [sessionId]
  );

  return (
    <>
      <Global
        styles={css`
          body {
            overflow: hidden;
          }
        `}
      />
      <Flex flex="1" h="100vh" flexDir={"row"} justifyContent={"space-between"}>
        <Flex
          flex="1"
          justifyContent="center"
          alignItems="center"
          flexDir={"column"}
        >
          <Text fontSize="28px" fontWeight="600" textAlign="center">
            Insert 6-Digit Code
          </Text>
          <HStack mt="32px">
            <PinInput
              placeholder=""
              focusBorderColor="#197C7D"
              onChange={setCode}
            >
              <PinInputField bgColor={"rgba(199, 199, 199, 0.72)"} />
              <PinInputField bgColor={"rgba(199, 199, 199, 0.72)"} />
              <PinInputField bgColor={"rgba(199, 199, 199, 0.72)"} />
              <PinInputField bgColor={"rgba(199, 199, 199, 0.72)"} />
              <PinInputField bgColor={"rgba(199, 199, 199, 0.72)"} />
              <PinInputField bgColor={"rgba(199, 199, 199, 0.72)"} />
            </PinInput>
          </HStack>
          <Button
            p="6.32px 22.12px"
            fontSize={"16px"}
            fontWeight={"500"}
            mt="38px"
            bgColor={"#197C7D"}
            textColor={"#FFFDFD"}
            h="auto"
            borderRadius={"9.48px"}
            onClick={call}
            isLoading={callState === "pending"}
          >
            Open Screen
          </Button>
        </Flex>
        <Flex
          flex="1"
          bgColor={"#197C7D"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Image src={QuincyLogo} w="45%" />
        </Flex>
      </Flex>
    </>
  );
};

export default HomeScreen;
