import { useWebRTC } from "../context/WebRTSProvider";

export const keyboardEvents = [
  "keydown",
  "keyup",
  // "keypress"
] as const;

type IKeyboardEvent = (typeof keyboardEvents)[number];

export interface IKeyboardEventData {
  type: IKeyboardEvent;
  key: string;

  code: string;
  ctrlKey: boolean;
  shiftKey: boolean;
  metaKey: boolean; // command on MacOs
  altKey: boolean;
}

export const useKeyboardEvent = () => {
  const { sendKeyboardEvent } = useWebRTC();

  const handleKeyboardEvent = (event: KeyboardEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const data: IKeyboardEventData = {
      type: event.type as IKeyboardEvent,
      key: event.key,

      code: event.code,
      ctrlKey: event.ctrlKey,
      shiftKey: event.shiftKey,
      metaKey: event.metaKey, // command on MacOs
      altKey: event.altKey,
    };
    sendKeyboardEvent(data);
  };

  return handleKeyboardEvent;
};
