import { useToast } from "@chakra-ui/react";
import {
  createContext,
  MutableRefObject,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

import io from "socket.io-client";
import { IMouseEventData, IWheelEventData } from "../hooks/useMouseEvent";

export enum SsGatewayEvents {
  JOIN = "join",
  LEAVE = "leave",
  CALL = "call",
  OFFER = "offer",
  ANSWER = "answer",
  ICE_CANDIDATE = "ice-candidate",
  MOUSE_ACTION = "mouse-action",
  KEYBOARD_ACTION = "keyboard-action",
}

export interface OfferRes {
  sessionId: string;
  payload: {
    offer: RTCSessionDescriptionInit;
    callerId: string;
    confirmed: boolean;
    active?: boolean;
    answer?: boolean;
  };
}
type CallState = "confirmed" | "rejected" | "pending" | "inactive" | "ended";

interface WebRTSState {
  call: () => void;
  sendMouseEvent: (data: IMouseEventData | IWheelEventData) => void;
  sendKeyboardEvent: (data: any) => void;
  sessionId: MutableRefObject<string>;
  callState: CallState | null;
  stream: MediaStream | null;
}

const WebRTSContext = createContext({} as WebRTSState);

export const WebRTSProvider = ({ children }: PropsWithChildren) => {
  const sessionId = useRef<string>("");
  const navigate = useNavigate();

  const [stream, setStream] = useState<MediaStream | null>(null);
  const socket = useRef(
    useMemo(
      () =>
        io("https://api.staging.gogoquincy.com/ss", {
          transports: ["websocket", "polling"],
        }),
      []
    )
  );
  const iceCandidates = useRef<Array<any>>([]);
  const peerConnection = useRef<RTCPeerConnection | null>(null);

  const toast = useToast({
    position: "top",
    isClosable: true,
  });

  const [callState, setCallState] = useState<CallState | null>(null);

  const call = useCallback(() => {
    socket.current.emit(SsGatewayEvents.CALL, {
      sessionId: sessionId.current,
    });
    setCallState("pending");
  }, []);

  const handleIncomingOffer = useCallback(
    async (data: OfferRes) => {
      if ("answer" in data.payload && !data.payload.answer) {
        setCallState("inactive");
        toast({
          title: `The Client is inactive`,
          status: "info",
        });
      } else if (!data.payload.confirmed) {
        setCallState("rejected");
        toast({
          title: `The Client rejected your request`,
          status: "info",
        });
      }
      if (data.payload.confirmed) {
        peerConnection.current = new RTCPeerConnection({
          iceServers: [
            { urls: "stun:stun.l.google.com:19302" },
            { urls: "stun:stun2.l.google.com:19302" },
            { urls: "stun:stun3.l.google.com:19302" },
            { urls: "stun:stun4.l.google.com:19302" },
          ],
        });

        peerConnection.current.onicecandidate = (e) => {
          if (e.candidate) {
            socket.current.emit(SsGatewayEvents.ICE_CANDIDATE, {
              sessionId: sessionId.current,
              payload: { candidate: e.candidate },
            });
          }
        };
        peerConnection.current.ontrack = (ev) => {
          setStream(ev.streams[0]);
        };
        await peerConnection.current.setRemoteDescription(
          new RTCSessionDescription(data.payload.offer)
        );

        if (iceCandidates.current.length) {
          iceCandidates.current.forEach(async (ice) => {
            await peerConnection.current?.addIceCandidate(
              new RTCIceCandidate(ice)
            );
          });
          iceCandidates.current = [];
        }

        await peerConnection.current
          .createAnswer()
          .then(async (answer) =>
            peerConnection.current?.setLocalDescription(answer)
          )
          .then(() => {
            socket.current.emit(SsGatewayEvents.ANSWER, {
              sessionId: sessionId.current,
              payload: {
                answer: peerConnection.current?.localDescription,
                callerId: data.payload.callerId,
              },
            });
          });

        navigate(`/conference/${data.payload.callerId}`);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    const socketInstance = socket.current;
    socketInstance.on(
      SsGatewayEvents.ICE_CANDIDATE,
      (data: {
        sessionId: string;
        payload: { candidate: RTCIceCandidate };
      }) => {
        if (peerConnection.current?.remoteDescription) {
          peerConnection.current?.addIceCandidate(
            new RTCIceCandidate(data.payload.candidate)
          );
        } else {
          iceCandidates.current.push(data.payload.candidate);
        }
      }
    );

    socketInstance.on(SsGatewayEvents.OFFER, handleIncomingOffer);
    socketInstance.on(SsGatewayEvents.LEAVE, () => {
      setStream(null);
      setCallState("ended");
      if (peerConnection.current) {
        peerConnection.current.onicecandidate = null;
        peerConnection.current.ontrack = null;
        peerConnection.current.close();
        peerConnection.current = null;
      }
    });

    return () => {
      socketInstance.disconnect();
    };
  }, [handleIncomingOffer]);

  const sendMouseEvent = (data: IMouseEventData | IWheelEventData) => {
    socket.current.emit(SsGatewayEvents.MOUSE_ACTION, {
      sessionId: sessionId.current,
      payload: data,
    });
  };
  const sendKeyboardEvent = (data: any) => {
    socket.current.emit(SsGatewayEvents.KEYBOARD_ACTION, {
      sessionId: sessionId.current,
      payload: data,
    });
  };

  return (
    <WebRTSContext.Provider
      value={{
        callState,
        call,
        sendMouseEvent,
        sendKeyboardEvent,
        sessionId,
        stream,
      }}
    >
      {children}
    </WebRTSContext.Provider>
  );
};

export const useWebRTC = () => useContext(WebRTSContext);
