import { useEffect, useRef } from "react";
import { useWebRTC } from "../context/WebRTSProvider";

export const mouseEvents = [
  "mousemove",
  "mousedown",
  "mouseup",
  "click",
  "dblclick",
  "contextmenu",
  "wheel",
] as const;

type IMouseEvent = (typeof mouseEvents)[number];

export interface IMouseEventData {
  type: IMouseEvent;
  x: number;
  y: number;
  clientWidth: number;
  clientHeight: number;
}

export interface IWheelEventData extends IMouseEventData {
  deltaX: number;
  deltaY: number;
}

export const useMouseEvent = () => {
  const { sendMouseEvent } = useWebRTC();
  const latestEventRef = useRef<IMouseEventData | null>(null);
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

  const handleWithDebounce = () => {
    if (!intervalRef.current) {
      intervalRef.current = setInterval(() => {
        if (latestEventRef.current) {
          sendMouseEvent(latestEventRef.current);
          latestEventRef.current = null; // Reset after sending
        } else {
          // Clear interval if no event detected
          clearInterval(intervalRef.current as ReturnType<typeof setInterval>);
          intervalRef.current = null;
        }
      }, 1000);
    }
  };

  const handleMouseEvent = (event: MouseEvent | WheelEvent) => {
    event.stopPropagation();
    event.preventDefault();

    const data: IMouseEventData = {
      type: event.type as IMouseEvent,
      x: event.offsetX,
      y: event.offsetY,
      clientWidth: (event.target as any).clientWidth,
      clientHeight: (event.target as any).clientHeight,
    };

    switch (data.type) {
      case "mousemove": {
        latestEventRef.current = data;
        return handleWithDebounce();
      }
      case "wheel": {
        const newData: IWheelEventData = {
          ...data,
          deltaX: (event as WheelEvent).deltaY,
          deltaY: (event as WheelEvent).deltaY,
        };
        latestEventRef.current = newData;
        return sendMouseEvent(newData);
      }
      default:
        break;
    }

    sendMouseEvent(data);
  };

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  return handleMouseEvent;
};
