import { useWebRTC } from "../../context/WebRTSProvider";
import { Box, Flex } from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import { Navigate } from "react-router-dom";
import { mouseEvents, useMouseEvent } from "../../hooks/useMouseEvent";
import { keyboardEvents, useKeyboardEvent } from "../../hooks/useKeyboardEvent";
import { css, Global } from "@emotion/react";

const ConferenceScreen = () => {
  const ref = useRef<HTMLVideoElement>(null);

  const { stream } = useWebRTC();
  const handleMouseEvent = useMouseEvent();
  const handleKeyboardEvent = useKeyboardEvent();

  useEffect(() => {
    if (stream && ref.current) {
      ref.current.srcObject = stream;
    }
  }, [stream]);

  useEffect(() => {
    const currentRef = ref.current;
    mouseEvents.forEach((event) =>
      event === "wheel"
        ? window.addEventListener("wheel", handleMouseEvent)
        : currentRef?.addEventListener(event, handleMouseEvent)
    );

    keyboardEvents.forEach((event) =>
      window.addEventListener(event, handleKeyboardEvent)
    );

    return () => {
      mouseEvents.forEach((event) => {
        event === "wheel"
          ? window.removeEventListener("wheel", handleMouseEvent)
          : currentRef?.removeEventListener(event, handleMouseEvent);
      });
      keyboardEvents.forEach((event) => {
        window.removeEventListener(event, handleKeyboardEvent);
      });
    };
  }, [handleMouseEvent, handleKeyboardEvent]);

  if (!stream) {
    return <Navigate to="/" replace={true} />;
  }

  return (
    <>
      <Global
        styles={css`
          body {
            overflow: hidden;
          }
        `}
      />
      <Flex
        position="relative"
        h="100vh"
        justifyContent="center"
        alignItems="center"
      >
        <Box as="video" ref={ref} width="auto" height="100%" autoPlay></Box>
      </Flex>
    </>
  );
};

export default ConferenceScreen;
